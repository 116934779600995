(function ($) {
    $.addressSupplementer = function (postalCode, houseNumber, street, city) {
        $(postalCode + "," + houseNumber).on("input", function () {
            if ($(postalCode).val().length > 5
                && $(houseNumber).val().length > 0
                && $(street).val().length === 0
                && $(city).val().length === 0) {
                
                $.ajaxAction("/PostalCode/GetAddress?postalCode=" + $(postalCode).val() + "&houseNumber=" + $(houseNumber).val())
                    .done(function(address) {
                        $(street).val(address.Street);
                        $(city).val(address.City);
                    });
            }
        });
    };
}(jQuery));