// Default ajax
$.ajaxSetup({
    cache: false
});

// Default moment.js
moment.locale('nl');

(function ($) {
    /* --- Override default validation methods --- */
    $.validator.methods.number = function(value, element) {
        return this.optional(element) || /^-?(?:\d+|\d{1,3})?(?:[\.,]\d+)?$/.test(value);
    };

    $.validator.methods.range = function(value, element, param) {
        var globalizedValue = value.replace(",", ".");
        return this.optional(element) || (globalizedValue >= param[0] && globalizedValue <= param[1]);
    };

    $.validator.methods.date = function (value, element) {
        var check;
        var re = /^\d{1,2}-\d{1,2}-\d{4}$/;
        if (re.test(value)) {
            var adata = value.split('-');
            var dd = parseInt(adata[0], 10);
            var mm = parseInt(adata[1], 10);
            var yyyy = parseInt(adata[2], 10);
            var xdata = new Date(yyyy, mm - 1, dd);
            if ((xdata.getFullYear() === yyyy) && (xdata.getMonth() === mm - 1) && (xdata.getDate() === dd))
                check = true;
            else
                check = false;
        } else
            check = false;
        return this.optional(element) || check;
    };

    Array.prototype.extend = function(other_array) {
        other_array.forEach(function(v) { this.push(v) }, this);
    };

    $.fn.initializeContextBindings = function () {
        $("form:not(.no-validation)", this).validation();
        $(".int-up-down", this).intUpDown();
        $(".datepicker-input", this).labelDatepicker();
        $(".datepicker").datepicker({
            format: "dd-mm-yyyy",
            clearBtn: true,
            autoclose: true
        });
        $(".toast").toast();
        $('.product-option-selector').select2({
            theme: "bootstrap"
        });

        $(".not-available").on("click", function() {
            alert("Nog niet beschikbaar.");
        });


        $(".confirm-delete").on("click", function() {
            if (!confirm("Weet u het zeker?")) {
                return false;
            }
        });
    };
}(jQuery));

$(function () {
    $("body").initializeContextBindings();
});


$(function () {

    var updateDisplay = function () {
        var incl = '€ ' + $('#vat-calculator-input-incl').val().replace('.', ',');
        var excl = '€ ' + $('#vat-calculator-input-excl').val().replace('.', ',');
        var vat = $('#vat-calculator-input-vat').val().replace('.', ',') + '%';

        $('#vat-calculator-input-incl').prev().text(incl);
        $('#vat-calculator-input-excl').prev().text(excl);
        $('#vat-calculator-input-vat').prev().text(vat);
    };

    var roundVat = function (value) {
        return Math.round(value * 10000) / 10000;
    };

    var calculateFromExcl = function () {
        var excl = $('#vat-calculator-input-excl').val().replace(',', '.') * 1;
        var vat = 1 + ($('#vat-calculator-input-vat').val().replace(',', '.') / 100);

        $('#vat-calculator-input-incl').val(roundVat(excl * vat));
        updateDisplay();
    };

    var calculateFromIncl = function () {
        var incl = $('#vat-calculator-input-incl').val().replace(',', '.') * 1;
        var vat = 1 + ($('#vat-calculator-input-vat').val().replace(',', '.') / 100);

        $('#vat-calculator-input-excl').val(roundVat(incl / vat));
        updateDisplay();
    };

    var applyValue = function() {
        var excl = $('#vat-calculator-input-excl').val().replace(',', '.') * 1;
        $('#vat-calculator').prev().val(excl);
        $('#vat-calculator').hide().appendTo('body');
        $('body').off('click.data-calculator');
    };

    $('#vat-calculator-input-excl, #vat-calculator-input-vat').on('change', function () {
        calculateFromExcl();
    });

    $('#vat-calculator-input-incl').on('change', function () {
        calculateFromIncl();
    });

    $('#vat-calculator-apply').on('click', function() {
        applyValue();
    });

    $('body').on('focusin', '[data-calculator]', function () {
        $('#vat-calculator').insertAfter(this).show();
        $('#vat-calculator-input-excl').val($(this).val().replace(',', '.')).trigger('change');

        $('body').on('click.data-calculator', function(e) {
            //if (!!$(e.target).closest('#vat-calculator'))
            //    return;

            if ($(e.target).closest('#vat-calculator').length > 0 || $(e.target).is('[data-calculator]'))
                return;

            $('#vat-calculator').hide().appendTo('body');
            $('body').off('click.data-calculator');
        });
    });

    $('#vat-calculator').on('keypress', function (e) {
        if (e.which == 13) {
            e.preventDefault();
            applyValue();
        }
    });


});