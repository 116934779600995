(function ($) {

    var showToast = function ($toastMessage, message, description) {
        $toastMessage.html("<strong><i class=\"fa fa-check\"></i>&nbsp;&nbsp;" + message + "</strong> " + description);
        $toastMessage.show();
    };

    $.fn.toast = function () {
        return this.each(function () {
            var $toastMessage = $(this);

            var messageCookie = Cookies.get("DropMessage");
            if (messageCookie == null) {
                return;
            }
            
            var message = JSON.parse(messageCookie);
            
            switch (message.DropMessageType) {
                case 0:
                    showToast($toastMessage, message.Message, message.Description);
                    break;

                case 1:
                    // Todo implement
                    break;

                case 2:
                    // Todo implement
                    break;

                case 3:
                    // Todo implement
                    break;
            }

            Cookies.remove("DropMessage");
        });
    };
}(jQuery));