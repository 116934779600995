(function ($) {
    var loadInvoiceStatistics = function ($widget, month, year) {
        month = typeof month !== "undefined" ? month : 0;
        year = typeof year !== "undefined" ? year : 0;

        $.ajaxAction("/Franchisee/Dashboard/InvoiceStatistics?year=" + year + "&month=" + month, null, { dataType: "html" })
            .done(function (data) {
                $widget.html(data);

                var paidAmount = parseFloat($widget.find(".invoiceWidget").data("paidAmount").replace(",", "."));
                var unpaidAmount = parseFloat($widget.find(".invoiceWidget").data("unpaidAmount").replace(",", "."));

                if (paidAmount == 0 && unpaidAmount == 0) {
                    paidAmount = 1;
                }

                var chart = new Chartist.Pie($widget.find(".invoicedChart").get(0), {
                    series: [paidAmount, unpaidAmount]
                }, {
                    donut: true,
                    showLabel: false
                });
            });
    };

    var nextMonth = function ($widget) {
        var month = $widget.find(".invoiceWidget").data("month");
        var year = $widget.find(".invoiceWidget").data("year");

        if (month == 12) {
            month = 1;
            year++;
        } else {
            month++;
        }

        loadInvoiceStatistics($widget, month, year);
    };

    var previousMonth = function ($widget) {
        var month = $widget.find(".invoiceWidget").data("month");
        var year = $widget.find(".invoiceWidget").data("year");

        if (month == 1) {
            month = 12;
            year--;
        } else {
            month--;
        }

        loadInvoiceStatistics($widget, month, year);
    };

    $.fn.invoicedWidget = function (options) {
        return this.each(function () {
            var $widget = $(this);

            $widget.on("click", ".next", function () {
                nextMonth($widget);
            });

            $widget.on("click", ".previous", function () {
                previousMonth($widget);
            });

            loadInvoiceStatistics($widget);
        });
    };
}(jQuery));