(function ($) {
    $.ajaxAction = function (url, data, options) {
        var isObject = (typeof data == "object");
        var deferred = $.Deferred();

        if (isObject) {
            data = JSON.stringify(data);
        } else if ($.isNumeric(data)) {
            data = "id=" + data;
        }

        var defaultOption = {
            type: "POST",
            url: url,
            data: data,
            cache: false,
            dataType: 'json',
            contentType: isObject ? "application/json; charset=utf-8" : "application/x-www-form-urlencoded; charset=utf-8"
        };

        jQuery.extend(defaultOption, options);
        
        $.ajax(defaultOption).fail(function (jqXhr) {
            // User is navigating away from page
            if (jqXhr.status === 0)
                return;

            var resultData;
            if (jqXhr.status == 500) {
                // alert(resultData.Message);
            } 
            
            deferred.reject();
            //location.reload(true);
        }).done(function (resultData) {
            deferred.resolve(resultData);
        });

        return deferred.promise();
    };
}(jQuery));